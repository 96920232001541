import React, { useState } from 'react';
import axios from 'axios';
import { FaWhatsapp, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Header from '../components/header';
import styles from './whatsapp-processor.module.css';

const WhatsappProcessor = () => {
 const [numbers, setNumbers] = useState('');
 const [processing, setProcessing] = useState(false);
 const [results, setResults] = useState(null);

 const processNumbers = async () => {
   if (!numbers.trim()) {
     toast.error('Cole o texto do WhatsApp primeiro');
     return;
   }

   setProcessing(true);
   try {
     const response = await axios.post(
       `${process.env.REACT_APP_DATABASE}/admin/users/process-whatsapp`,
       { numbers },
       {
         headers: {
           Authorization: `Bearer ${localStorage.getItem('token')}`
         }
       }
     );
     
     setResults(response.data.results);
     toast.success(`${response.data.processed} números processados com sucesso!`);
   } catch (error) {
     toast.error(error.response?.data?.message || 'Erro ao processar números');
   } finally {
     setProcessing(false);
   }
 };

 return (
   <div className={styles.whatsappProcessorMain}>
     <Header />
     <div className={styles.whatsappProcessorContainer}>
       <h1 className={styles.whatsappProcessorTitle}>
         Processador WhatsApp
       </h1>
       <p className={styles.whatsappProcessorDescription}>
         Cole o texto do WhatsApp para atualizar o status dos usuários no grupo
       </p>

       <div className={styles.processorPanel}>
         <textarea
           className={styles.textarea}
           value={numbers}
           onChange={(e) => setNumbers(e.target.value)}
           placeholder="Cole aqui o texto do WhatsApp..."
         />
         
         <button
           className={styles.processButton}
           onClick={processNumbers}
           disabled={processing}
         >
           {processing ? (
             'Processando...'
           ) : (
             <>
               <FaWhatsapp /> Processar Números
             </>
           )}
         </button>
       </div>

       {results && (
         <div className={styles.resultsPanel}>
           <h2 className={styles.resultsTitle}>Resultados do Processamento</h2>
           
           <div className={styles.resultsGrid}>
             <div className={styles.resultSection}>
               <h3>
                 <FaCheck className={styles.successIcon} />
                 Processados com Sucesso ({results.success.length})
               </h3>
               <div className={styles.resultList}>
                 {results.success.map((item, index) => (
                   <div key={index} className={styles.resultItem}>
                     <span className={styles.phone}>{item.phone}</span>
                     <span className={`${styles.status} ${item.action === 'added' ? styles.added : styles.removed}`}>
                       {item.action === 'added' ? 'Entrou' : 'Saiu'}
                     </span>
                     <span className={styles.username}>{item.username}</span>
                   </div>
                 ))}
               </div>
             </div>

             {results.failed.length > 0 && (
               <div className={styles.resultSection}>
                 <h3>
                   <FaTimes className={styles.errorIcon} />
                   Falhas no Processamento ({results.failed.length})
                 </h3>
                 <div className={styles.resultList}>
                   {results.failed.map((item, index) => (
                     <div key={index} className={styles.resultItem}>
                       <span className={styles.phone}>{item.phone}</span>
                       <span className={styles.errorReason}>
                         {item.reason === 'user_not_found' 
                           ? 'Usuário não encontrado' 
                           : 'Erro no processamento'}
                       </span>
                     </div>
                   ))}
                 </div>
               </div>
             )}
           </div>
         </div>
       )}
     </div>
   </div>
 );
};

export default WhatsappProcessor;